.payment {
  .base {
    padding: 20px;
    align-items: center;
  }

  .header {
    padding: 0 0 10px;

    .controls {
      display: flex;
      justify-content: flex-end;
    }
  }
}
