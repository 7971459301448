.privacy {
  padding: 50px 0;
  margin-top: 20px;

  .base {
    padding: 0 0 10px;

    .google {
      font-weight: normal;
      margin-bottom: 20px;
    }

    .section {
      margin-left: 20px;

      .list {
        font-weight: bold;

        .items {
          margin-left: 20px;

          .title {
            font-weight: bold;

            .content {
              font-weight: normal;
              margin-left: 10px;

              .paragraph {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }

    .unlist-content {
      font-weight: normal;
    }
  }
}
