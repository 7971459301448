.modal {
  .base {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    max-height: 600px;
    overflow-y: auto;

    &.-mobile {
      width: 300px;
    }

    .connect {
      margin-bottom: 15px;
      margin-left: 25px;
    }

    .details {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 10px;
      display: flex;
      max-width: 100%;

      .content {
        flex-direction: column;

        .label {
          font-weight: bold;

          .text {
            font-weight: normal;
          }
        }
      }
    }

    .action {
      margin-top: 20px;
    }
  }
}

.accounts {
  margin-bottom: 15px;
  width: 100%;

  .details {
    margin-bottom: 10px;
    margin-left: 10px;
    display: flex;

    .content {
      flex-direction: column;

      .label {
        font-weight: bold;

        .text {
          font-weight: normal;
        }
      }
    }
  }
}

.campaigns {
  margin-bottom: 15px;
  width: 100%;
  margin-left: 5px;

  .details {
    margin-bottom: 10px;
    margin-left: 10px;
    display: flex;

    .content {
      flex-direction: column;

      .label {
        font-weight: bold;

        .text {
          font-weight: normal;
        }
      }
    }
  }
}
