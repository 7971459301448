.terms {
  padding: 50px 0;
  margin-top: 20px;

  .base {
    padding: 0 0 10px;
    flex-direction: column;

    .content {
      font-size: 18px;
      margin-bottom: 10px;

      &.-margin-20 {
        margin-bottom: 20px;
      }

      .bold {
        font-size: 18px;
        font-weight: bold;
        margin-right: 5px;
        margin-left: 5px;

        .link {
          margin-left: 5px;
        }
      }
    }

    .list {
      margin-left: 20px;

      .item {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
  }
}
