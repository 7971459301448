.navbar {
  &.-public {
    width: 100%;
    padding: 100px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    width: 120px;
    height: 45px;

    &.-public {
      text-align: center;
      width: 100%;
    }
  }
}
