.payment-success {
  .base {
    text-align: center;
    flex-direction: column;

    .image {
      width: 450px;
      height: 450px;

      &.-mobile {
        width: 300px;
        height: 300px;
      }
    }

    .action {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
