.modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.-mobile {
    width: 300px;
  }

  .header {
    max-width: 380px;
    margin-top: 15px;
  }

  .subtitle {
    max-width: 380px;
    margin: 15px 0;
    text-align: center;
  }

  .field {
    width: 100%;
    margin-bottom: 15px;
  }

  .iframe {
    text-align: center;
    margin-top: 10px;
  }
}
