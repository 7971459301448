@import '../../variables/color.scss';

.dialog {
  .list {
    &.-active {
      background-color: $light-goldenrod-yellow;
    }
  }

  .chat-icon {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .chat-icon {
    margin-bottom: 5px;
    margin-left: 5px;
  }

  .time {
    &.-new-message {
      font-weight: bold;
      color: black;
    }
  }

  .message {
    display: flex;
    justify-content: flex-start;

    &.-own-message {
      justify-content: flex-end;
    }

    .profile {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      margin-right: 8px;
      display: inline-block;
    }

    .content {
      width: fit-content;
      max-width: 100%;
      background-color: $other-message-bg;
      border-radius: 27px;
      display: flex;
      flex-direction: column;
      padding: 10px;
      font-size: 12px;
      color: $dark-gray;

      &.-own-message {
        background-color: $christi;
        color: $white;
      }
    }

    .attachment {
      &.-image {
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &.-own-message {
          justify-content: flex-end;
        }
      }

      &.-file {
        width: fit-content;
        max-width: 100%;
        background: $other-message-bg;
        border-radius: 18px;
        display: flex;
        flex-direction: column;
        padding: 0 12px;
        font-size: 12px;
        color: $white;

        &.-own-message {
          background: $own-message-bg;
        }

        .text {
          &.-own-message {
            color: $white;
          }
        }
      }
    }
  }

  .no-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    .text {
      color: $grey;
    }
  }
}

.user {
  position: relative;
  flex-direction: column;
  padding-left: 10px;
  margin: 20px 0 20px;
  min-height: calc(100vh - 400px);
  max-height: calc(100vh - 400px);

  .avatar {
    display: inline-block;
    text-align: center;
    width: 100%;

    .name {
      text-align: center;
    }
  }

  .details {
    align-items: center;
    flex-wrap: wrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    margin-top: 10px;
  }
}

.form {
  .file-details {
    color: $grey;
    padding: 0;
    font-size: 10px;

    &.-error {
      color: $red;
    }
  }

  .remove {
    margin-left: 5px;
    color: $red;
    text-decoration: underline;
    cursor: pointer;
  }
}
